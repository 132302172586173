<template>
  <section>
    <div v-if="!textOnly">
      <b-form-group :label="$t('RECURRENCE.RECURRENCE')" label-cols-md="2">
        <div class="d-flex flex-row">
          <div>
            <b-form-select
              v-model="selectedFrequency"
              :options="frequencyOptions"
              @change="ChangedFrequency"
              :aria-label="$t('NARR.RECURRENCE.SELECT_RECURRENCE_FREQUENCY')"
            />
          </div>
          <div>
            <b-button
              variant="primary"
              class="no-border ml-1"
              @click="OpenDialog"
              v-if="selectedFrequency != 'None'"
            >
              {{ $t("RECURRENCE.CUSTOMIZE") }}
            </b-button>
          </div>
        </div>
      </b-form-group>

      <b-modal
        id="custom-modal"
        v-model="showCustomModal"
        hide-header
        centered
        @ok="Save"
        @cancel="Cancel"
        :cancel-title="$t('CANCEL')"
        :ok-disabled="!saveDisabled"
      >
        <div v-if="RecurrenceDTO">
          <div :key="'recurrenceKey_' + RecurrenceDTO.frequency + customFrequencyOptions">

            <b-form-group>
              <label>{{ $t("RECURRENCE.REPETITION") }}</label>
              <div class="d-flex align-items-center">
                <span class="mr-2">{{ $t("RECURRENCE.EVERY") }}</span>
                <div>
                  <div
                    :class="{
                      'recurrence-input-wrapper': true,
                      'is-invalid': !isValidInterval,
                    }"
                  >
                    <b-form-input
                      type="number"
                      v-model.number="RecurrenceDTO.everyInterval"
                      @change="CheckCustom"
                      min="1"
                      max="999"
                      :aria-label="$t('NARR.RECURRENCE.INPUT_INTERVAL')"
                    ></b-form-input>
                  </div>
                </div>
                <b-form-select
                  v-model="RecurrenceDTO.frequency"
                  :options="customFrequencyOptions"
                  @change="ChangedCustomFrequency"
                  class="ml-2"
                  :aria-label="
                    $t('NARR.RECURRENCE.SELECT_RECURRENCE_FREQUENCY')
                  "
                >
                </b-form-select>
              </div>
            </b-form-group>

            <div v-if="RecurrenceDTO.frequency == 'Weekly'" class="mt-3">
              <b-form-group>
                <label>{{ $t("RECURRENCE.DAYS_OF_THE_WEEK") }}</label>
                <div class="recurrence-days-button-group">
                  <b-button
                    v-for="day in daysOfWeekOptions"
                    :key="day.value"
                    :variant="
                      isDaySelected(day.value) ? 'primary' : 'outline-primary'
                    "
                    @click="toggleDay(day.value)"
                    class="recurrence-day-button"
                    :aria-label="`${day.text}`"
                  >
                    {{ day.text.charAt(0) }}
                  </b-button>
                </div>
              </b-form-group>
            </div>

            <b-form-group class="mt-3">
              <b-form-radio-group
                @change="ChangedEndType"
                v-model="endType"
                :aria-label="$t('NARR.RECURRENCE.SELECT_END_TYPE')"
              >
                <b-form-radio
                  value="untilDate"
                  :aria-label="$t('RECURRENCE.UNTIL_DATE')"
                >
                  {{ $t("RECURRENCE.UNTIL_DATE") }}
                </b-form-radio>
                <b-form-radio
                  value="occurrences"
                  :aria-label="$t('NARR.RECURRENCE.SELECT_OCCURRENCES')"
                >
                  {{ $t("RECURRENCE.OCCURRENCES") }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <div v-if="endType === 'untilDate'">
              <b-form-group>
                <PickDate
                  v-model="RecurrenceDTO.untilDate"
                  @change="CheckCustom"
                  @valid="(n) => (validUntilDate = n)"
                ></PickDate>
              </b-form-group>
            </div>

            <div v-if="endType === 'occurrences'">
              <b-form-group>
                <div
                  :class="{
                    'recurrence-input-wrapper': true,
                    'is-invalid': !isValidOccurrences,
                  }"
                >
                  <b-form-input
                    type="number"
                    v-model.number="RecurrenceDTO.occurrences"
                    @change="CheckCustom"
                    min="2"
                    class="recurrence-bordered-input recurrence-form-control"
                    :aria-label="
                      $t('NARR.RECURRENCE.INPUT_NUMBER_OF_OCCURRENCES')
                    "
                  ></b-form-input>
                </div>
              </b-form-group>
            </div>

            <div
              v-if="
                RecurrenceDTO.frequency !== 'None' &&
                RecurrenceDTO.everyInterval
              "
              class="mt-3"
            >
              <p>{{ recurrenceSummary }}</p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="textOnly">{{ recurrenceSummary }}</div>
  </section>
</template>

<script>
import { DateTime } from "luxon";
import PickDate from "./PickDate";
export default {
  components: {
    PickDate,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    recurrence: {
      type: Object,
      default: null
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    textOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minDate: "0001-01-01T00:00:00",
      maxDate: "9999-12-31T23:59:59",
      datetime: null,
      validUntilDate: true,
      RecurrenceDTO: {
        frequency: "None",
        occurrences: 1,
        occurrenceDays: [],
        untilDate: null,
        everyInterval: 1,
      },
      selectedFrequency: "None",
      CurrentSelectedFrequency: "None",
      endType: "untilDate",
      CurrentRecurrenceDTO: null,
      showCustomModal: false,
      selectedMonthlyOption: "specificDate",
      modalSaved: false,
      customFrequencyOptions: [
        { value: "Daily", text: this.$t("RECURRENCE.DAY") },
        { value: "Weekly", text: this.$t("RECURRENCE.WEEK") },
        { value: "Monthly", text: this.$t("RECURRENCE.MONTH") },
        { value: "Yearly", text: this.$t("RECURRENCE.YEAR") },
      ],
      daysOfWeekOptions: [
        { value: "Monday", text: this.$t("RECURRENCE.MONDAY") },
        { value: "Tuesday", text: this.$t("RECURRENCE.TUESDAY") },
        { value: "Wednesday", text: this.$t("RECURRENCE.WEDNESDAY") },
        { value: "Thursday", text: this.$t("RECURRENCE.THURSDAY") },
        { value: "Friday", text: this.$t("RECURRENCE.FRIDAY") },
        { value: "Saturday", text: this.$t("RECURRENCE.SATURDAY") },
        { value: "Sunday", text: this.$t("RECURRENCE.SUNDAY") },
      ],
      custom: false,
      daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      dayMap: {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
      },
    };
  },
  methods: {
    CheckCustom()
    {
      if(this.IsCustom(this.RecurrenceDTO))
      {
        this.selectedFrequency = "Custom";
      }
    },
    OpenDialog() {
      this.$emit('opened', true);
      this.showCustomModal = true;
    },
    ChangedEndType(){
      if(this.endType == "occurrences")
      {
        this.RecurrenceDTO.occurrences = 2;        
        this.RecurrenceDTO.untilDate = null;
      }
      if(this.endType == "untilDate")
      {
        const fromDate = DateTime.fromISO(this.datetime);
        switch (this.RecurrenceDTO.frequency) {
          case "Daily":
            this.RecurrenceDTO.untilDate = DateTime.fromISO(fromDate.plus({ months: 1 }), { zone: 'utc' }).toISO();
            break;
          case "Weekly":
            this.RecurrenceDTO.untilDate = DateTime.fromISO(fromDate.plus({ months: 3 }), { zone: 'utc' }).toISO();
            break;
          case "Monthly":
            this.RecurrenceDTO.untilDate = DateTime.fromISO(fromDate.plus({ years: 1 }), { zone: 'utc' }).toISO();
            break;
          case "Custom":
          case "Yearly":
            this.RecurrenceDTO.untilDate = DateTime.fromISO(fromDate.plus({ years: 3 }), { zone: 'utc' }).toISO();
            break;
        }
        this.RecurrenceDTO.occurrences = null;
        this.CheckCustom();
      }
    },
    changedMonthlyOption() {
      this.RecurrenceDTO.occurrenceDays = [];
      const startDate = DateTime.fromISO(this.startDate);
      if (this.selectedMonthlyOption == "dayOfWeek") {
        this.RecurrenceDTO.occurrenceDays = { offset: startDate.weekday, dayOfWeek: this.daysOfWeek[startDate.weekday-1] };
      }
      if (this.selectedMonthlyOption == "specificDate") {
        this.RecurrenceDTO.occurrenceDays = [];
      }
      this.CheckCustom();
    },
    ChangedFrequency() {
      this.SetRecurrenceDTO(this.selectedFrequency, true);
    },    
    ChangedCustomFrequency() {
      this.SetRecurrenceDTO(this.RecurrenceDTO.frequency, false);
    },    
    SetRecurrenceDTO(frequency, useDefault) {
      const fromDate = DateTime.fromISO(this.datetime);
      let datetimeDaily = DateTime.fromISO(fromDate.plus({ months: 1 }), { zone: 'utc' }).toISO();
      let datetimeWeekly = DateTime.fromISO(fromDate.plus({ months: 3 }), { zone: 'utc' }).toISO();
      let datetimeMonthly = DateTime.fromISO(fromDate.plus({ years: 1 }), { zone: 'utc' }).toISO();
      let datetimeYearly = DateTime.fromISO(fromDate.plus({ years: 3 }), { zone: 'utc' }).toISO();
      if(useDefault){
        this.endType = 'untilDate';
      }
      switch (frequency) {
        case "None":
          this.selectedFrequency = "None";
          this.RecurrenceDTO = null;
          if(!this.textOnly){ this.$emit("input", null); this.$emit('changed'); }          
          break;
        case "Daily":
          this.selectedFrequency = "Daily";
          this.RecurrenceDTO = {
            frequency: "Daily",
            occurrences: this.endType == 'occurrences' ? 2 : null,
            occurrenceDays: [],
            untilDate: this.endType == 'untilDate' ? datetimeDaily : null,
            everyInterval: 1,
          };
          this.OpenDialog();
          break;
        case "Weekly":
          this.selectedFrequency = "Weekly";
          this.RecurrenceDTO = {
            frequency: "Weekly",
            occurrences: this.endType == 'occurrences' ? 2 : null,
            occurrenceDays: [{ offset: 0, dayOfWeek: this.daysOfWeek[fromDate.weekday-1] }],
            untilDate: this.endType == 'untilDate' ? datetimeWeekly : null,
            everyInterval: 1,
          };
          this.OpenDialog();
          break;
        case "Monthly":
          this.selectedFrequency = "Monthly";
          this.RecurrenceDTO = {
            frequency: "Monthly",
            occurrences: this.endType == 'occurrences' ? 2 : null,
            occurrenceDays: [],
            untilDate: this.endType == 'untilDate' ?  datetimeMonthly : null,
            everyInterval: 1,
          };
          this.OpenDialog();
          break;
        case "Yearly":
          this.selectedFrequency = "Yearly";
          this.RecurrenceDTO = {
            frequency: "Yearly",
            occurrences: this.endType == 'occurrences' ? 2 : null,
            occurrenceDays: [],
            untilDate: this.endType == 'untilDate' ? datetimeYearly : null,
            everyInterval: 1,
          };
          this.OpenDialog();
          break;
        case "Custom":        
          this.selectedFrequency = "Custom";          
          this.RecurrenceDTO = {
            frequency: "Yearly",
            occurrences: this.endType == 'occurrences' ? 2 : null,
            occurrenceDays: [],
            untilDate: this.endType == 'untilDate' ? datetimeYearly : null,
            everyInterval: 1,
          };
          this.OpenDialog();
          break;
      }
    },
    Init(value) {
      if (value) {          
        this.endType = value.occurrences == null ? "untilDate" : "occurrences";        
        if(value.occurrences != null)
        {
          this.selectedMonthlyOption = value.occurrences.length == 0 ? "specificDate" : "dayOfWeek";
        }else{
          this.selectedMonthlyOption = "specificDate";
        }
        const occurrenceDays = value.occurrenceDays.map(item => (
          { 
            offset: item.offset,
            dayOfWeek: item.dayOfWeek
          }
        ));
        this.RecurrenceDTO = {
          occurrenceDays: occurrenceDays,
          frequency: value.frequency,
          occurrences: value.occurrences,
          untilDate: value.untilDate,
          everyInterval: value.everyInterval
        }; 
        let custom = this.IsCustom(this.RecurrenceDTO);
        this.selectedFrequency = custom == true ? "Custom" : value.frequency;
        this.CurrentRecurrenceDTO = this.RecurrenceDTO;
      } else {       
        this.selectedFrequency = "None";
        this.CurrentRecurrenceDTO = null;
        this.RecurrenceDTO = null;
      }
      this.CurrentSelectedFrequency = this.selectedFrequency;
    },
    Save() {
      if(this.textOnly){ return; }
      this.CurrentRecurrenceDTO = this.RecurrenceDTO;
      if(this.RecurrenceDTO.occurrences != null || this.RecurrenceDTO.everyInterval > 1)
      {
        this.selectedFrequency = "Custom";
      }
      this.CurrentSelectedFrequency = this.selectedFrequency;
      this.$emit("input", this.RecurrenceDTO);
      this.$emit('changed');
      this.$emit('opened', false);      
      this.showCustomModal = false;
    },
    Cancel() {
      if(this.textOnly){ return; }
      this.selectedFrequency = this.CurrentSelectedFrequency;
      this.RecurrenceDTO = this.CurrentRecurrenceDTO;      
      this.$emit('opened', false);
      this.showCustomModal = false;
    },
    toggleDay(dayOfWeek) {
      if(!this.custom)
      {
        this.selectedFrequency = "Custom";
        this.custom = true;
      }
      const index = this.RecurrenceDTO.occurrenceDays.findIndex(
        (d) => d.dayOfWeek === dayOfWeek
      );
      if (index === -1) {
        this.RecurrenceDTO.occurrenceDays.push({ dayOfWeek, offset: 0 });
      } else {
        this.RecurrenceDTO.occurrenceDays.splice(index, 1);
      }
      this.CheckCustom();
    },
    isDaySelected(dayOfWeek) {
      return this.RecurrenceDTO.occurrenceDays.some(
        (d) => d.dayOfWeek === dayOfWeek
      );
    },
    GetDayOccurrence(date)
    {
      let week = Math.ceil(date.day / 7);
      return week == 5 ? -1 : week;
    },
    IsCustom(dto) {
      if(dto.occurrences != null || dto.everyInterval > 1)
      {
        return true;
      }    
      const fromDate = DateTime.fromISO(this.datetime);
      switch (dto.frequency) {
        case "Daily":
          if(dto.untilDate != DateTime.fromISO(fromDate.plus({ months: 1 }), { zone: 'utc' }).toISO({suppressMilliseconds: true}))
          {
            return true;
          }
          break;
        case "Weekly":
          if(dto.occurrenceDays.length != 1)
          {
            return true;
          }
          if(dto.occurrenceDays[0].dayOfWeek != this.daysOfWeek[fromDate.weekday-1])
          {
            return true;
          }          
          if(dto.untilDate != DateTime.fromISO(fromDate.plus({ months: 3 }), { zone: 'utc' }).toISO({suppressMilliseconds: true}))
          {
            return true;
          }
          break;
        case "Monthly":            
          if(dto.untilDate != DateTime.fromISO(fromDate.plus({ years: 1 }), { zone: 'utc' }).toISO({suppressMilliseconds: true}))
          {
            return true;
          }
          break;
        case "Yearly":
          if(dto.untilDate != DateTime.fromISO(fromDate.plus({ years: 3 }), { zone: 'utc' }).toISO({suppressMilliseconds: true}))
          {
            return true;
          }
          break;
      }
      return false;
    },
  },
  computed: {
    frequencyOptions() {
      const fromDate = DateTime.fromISO(this.datetime);
      if (fromDate.toFormat("MM") == "Invalid DateTime") return [];
      let options = [];
      options.push({ value: "None", text: this.$t("RECURRENCE.NONE") });
      options.push({ value: "Daily", text: this.$t("RECURRENCE.DAILY") });
      options.push({
        value: "Weekly",
        text: this.$t("RECURRENCE.WEEK_DAY." + fromDate.weekday),
      });
      options.push({
        value: "Monthly",
        text: this.$t("RECURRENCE.THE") + " " + fromDate.day + " " + this.$t("RECURRENCE.EVERY_MONTH"),
      });
      options.push({
        value: "Yearly",
        text:
          this.$t("RECURRENCE.YEARLY_MONTH."  + fromDate.month) +
          " " +
          fromDate.day,
      });
      options.push({ value: "Custom", text: this.$t("RECURRENCE.CUSTOM") });
      return options;
    },
    monthlyOptions() {
      const fromDate = DateTime.fromISO(this.datetime);
      let occurrence = this.GetDayOccurrence(fromDate);
      let monthlyText = this.$t("RECURRENCE.MONTH_OCCURRENCE." + occurrence);
      monthlyText += " " + this.$t("RECURRENCE.MONTH_DAY." + fromDate.weekday);
      return [
        {
          value: "specificDate",
          text:
            this.$t("RECURRENCE.THE") +
            " " +
            fromDate.day +
            " " +
            this.$t("RECURRENCE.EVERY_MONTH"),
        },
        {
          value: "dayOfWeek",
          text: monthlyText
        },
      ];
    },
    isValidInterval() {
      return (
        this.RecurrenceDTO &&
        this.RecurrenceDTO.everyInterval > 0 &&
        this.RecurrenceDTO.everyInterval <= 999
      );
    },
    isValidOccurrences() {
      return (
        this.endType === "occurrences" && this.RecurrenceDTO.occurrences >= 2
      );
    },
    saveDisabled() {
      if (!this.RecurrenceDTO) return true;
      if(this.RecurrenceDTO.frequency == "Weekly")
      {
        return this.RecurrenceDTO.occurrenceDays.length != 0;
      }
      return this.endType === "occurrences" ? this.isValidOccurrences : this.validUntilDate && this.isValidInterval;
    },
    recurrenceSummary() {
      if (!this.RecurrenceDTO || this.RecurrenceDTO.frequency === "None") {
        return "";
      }
      let summary = this.$t("RECURRENCE.THIS_MEETING_OCCURS") + " ";

      const startDate = DateTime.fromISO(this.startDate);
      const endDate = DateTime.fromISO(this.endDate);

      const diff = endDate.diff(startDate, ["years", "months", "days", "hours", "minutes"])
      
      summary += startDate.toFormat('HH:mm') + " ";
      if(diff.years != 0 || diff.months != 0 || diff.days != 0 || diff.hours != 0 || diff.minutes != 0)
      {
        let diffSummary = "";
        if(diff.years != 0)
        {
          diffSummary += diff.years + " år";
        }
        if(diff.months != 0)
        {
          if(diffSummary != ""){ diffSummary += ", "; }
          diffSummary += diff.months + " " + (diff.months < 9 ? this.$t("RECURRENCE.TIME.months") : this.$t("RECURRENCE.TIME.month"));
        }
        if(diff.days != 0)
        {
          if(diffSummary != ""){ diffSummary += ", "; }
          diffSummary += diff.days + " " + (diff.days > 9 ? this.$t("RECURRENCE.TIME.days") : this.$t("RECURRENCE.TIME.day"));
        }
        if(diff.hours != 0)
        {
          if(diffSummary != ""){ diffSummary += ", "; }
          diffSummary += diff.hours + " " + (diff.hours > 9 ? this.$t("RECURRENCE.TIME.hours") : this.$t("RECURRENCE.TIME.hour"));
        }
        if(diff.minutes != 0)
        {
          if(diffSummary != ""){ diffSummary += ", "; }
          diffSummary += diff.minutes + " " + (diff.minutes > 9 ? this.$t("RECURRENCE.TIME.minutes") : this.$t("RECURRENCE.TIME.minute"));
        }
        summary += "(i " + diffSummary + ") ";
      }

      if (this.RecurrenceDTO.everyInterval === 1) {
        summary += this.$t("RECURRENCE.EVERY").toLowerCase() + " ";
      } else if (this.RecurrenceDTO.everyInterval === 2) {
        summary += this.$t("RECURRENCE.EVERY_OTHER").toLowerCase() + " ";
      } else if (this.RecurrenceDTO.everyInterval === 3) {
        summary += this.$t("RECURRENCE.EVERY_THIRD").toLowerCase() + " ";
      } else if (this.RecurrenceDTO.everyInterval > 3) {
        summary +=
          this.$t("RECURRENCE.EVERY").toLowerCase() +
          " " +
          this.RecurrenceDTO.everyInterval +
          " ";
      }
      switch (this.RecurrenceDTO.frequency) {
        case "Daily":
          summary += this.$t("RECURRENCE.DAY").toLowerCase();
          break;
        case "Weekly": {
          summary +=
            this.$t("RECURRENCE.WEEK").toLowerCase() +
            " " +
            this.$t("RECURRENCE.ON") +
            " ";

          let days = this.RecurrenceDTO.occurrenceDays;
          days.sort((a, b) => {
            return this.dayMap[a.dayOfWeek] - this.dayMap[b.dayOfWeek];
          });
          summary += days
            .map((day) => {
              return this.$t("RECURRENCE.WEEKDAY_NAME." + day.dayOfWeek);
            })
            .join(", ");
          break;
        }
        case "Monthly":
          summary += " " + this.$t("RECURRENCE.MONTH").toLowerCase();
          if (this.selectedMonthlyOption === "dayOfWeek") {

            let occurrence = this.GetDayOccurrence(startDate);
            let monthlyText = this.$t("RECURRENCE.MONTH_OCCURRENCE." + occurrence);
            monthlyText += " " + this.$t("RECURRENCE.MONTH_DAY." + startDate.weekday);
            summary +=
              ". " +
              monthlyText;
          } else {
            summary +=
              ". " +
              this.$t("RECURRENCE.THE") +
              " " +
              startDate.day +
              " " +
              this.$t("RECURRENCE.EVERY_MONTH");
          }
          break;
        case "Yearly":
          summary += this.$t("RECURRENCE.YEAR").toLowerCase();
          break;
      }
      if (this.endType === "occurrences") {
        summary += ` ${this.$t("RECURRENCE.WITH")} ${
          this.RecurrenceDTO.occurrences
        } ${this.$t("RECURRENCE.OCCURRENCES").toLowerCase()}.`;
      } else if (this.endType === "untilDate") {
        let formattedUntilDate = DateTime.fromISO(this.RecurrenceDTO.untilDate).toFormat("yyyy-LL-dd");
        if(formattedUntilDate != "0001-01-01" && formattedUntilDate != "9999-12-31")
        {
          summary += " " + this.$t("RECURRENCE.UNTIL") + " ";
          if (this.validUntilDate && this.RecurrenceDTO.untilDate) {            
            summary += formattedUntilDate + ".";
          } else {
            summary += this.$t("RECURRENCE.SET_DATE") + "." + this.validUntilDate;
          }
        }else{
          summary += ".";
        }
      }

      const formattedDate = DateTime.fromISO(this.datetime).toFormat(
        "yyyy-LL-dd"
      );
      summary +=
        " " + this.$t("RECURRENCE.MEETING_STARTING_ON") + " " + formattedDate;
      return summary;
    },
  },
  watch: {
    startDate: function (val) {
      if(val != null && val != "")
      {
        this.datetime = val;
      }
    },
    saveDisabled(){
      this.$emit('valid',this.saveDisabled);
    },
    recurrence: function(val) {
      this.Init(val);
    }
  },
  mounted() {
    this.datetime = this.startDate;
    if(!this.textOnly){
      this.Init(this.value);
    }else{
      this.Init(this.recurrence);
    }
  },
};
</script>