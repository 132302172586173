<template>
  <span>
    <div class="attachment">
      <div class="icon d-print-none"><i class="fal fa-file"></i></div>
      <div class="information">
        <div class="name" v-b-tooltip.hover :title="info.name">
          {{ info.name }}
        </div>
        <div class="filesize">
          <span v-if="info.size">{{ bytesToSize(info.size) }} &nbsp;</span>
        </div>
        <div class="menu d-print-none">
          <b-dropdown size="sm" toggle-class="no-border" no-caret>
            <template #button-content>
              <i class="fal fa-ellipsis clickable"></i>
            </template>
            <b-dropdown-item href="#" @click="Download">{{
              $t("DOWNLOAD")
            }}</b-dropdown-item>
            <b-dropdown-item
              href="#"
              v-if="filestorage"
              @click="SaveToFilestorage"
              >{{ $t("SAVE_FILESTORAGE") }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-modal
      scrollable
      v-model="show"
      :id="'save-attachment-' + info.name"
      hide-header
      size="lg"
      @cancel="cancelSave"
      @ok="okSave"
      centered
      :ok-disabled="folder_uuid == ''"
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('SAVE')"
    >
      <ListFolders v-model="folder_uuid"></ListFolders>
    </b-modal>
  </span>
</template>
<script>
import ListFolders from "../FileStorage/ListFolders";
export default {
  components: { ListFolders },
  props: ["item","index","message_uuid", "link_uuid", "user_uuid", "functionbox_uuid","filestorage"],
  data() {
    return {
      show: false,
      loading: false,
      info: this.item,
      download_uuid: '',
      folder_uuid: ''
    };
  },
  methods: {
    async okSave(){
      let AddAttachmentRequestDTO = {
        user_uuid: this.user_uuid,
        functinobox_uuid: this.functionbox_uuid,
        message_uuid: this.message_uuid,
        attachment_uuid: "",
        folder_uuid: this.folder_uuid,
        attachment_index: 0
      };
      if(this.info.attachment_uuid != undefined){
        AddAttachmentRequestDTO.attachment_uuid = this.info.attachment_uuid;
        AddAttachmentRequestDTO.attachment_index = 0;
      }
      try {
        await this.$http.post(
          this.user.hostname + "/filestorage/attachment/add", AddAttachmentRequestDTO
        );
        this.$noty.info(this.$t("SAVED"));
      } catch (error) {
        return null;
      }
    },
    cancelSave(){
      this.show = false;
      this.folder = "";
    },
    async Download() {
      if(this.link_uuid)
      {
        let AttachmentDTO = 
        {
          link_uuid: this.link_uuid,
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,       
          message_uuid: this.message_uuid,     
          attachment_index: this.index,
          attachment_uuid: ''
        };
        if(this.info.attachment_uuid != undefined){
          AttachmentDTO.attachment_uuid = this.info.attachment_uuid;
          AttachmentDTO.attachment_index = 0;
        }
        try {
          let result = await this.$http.post(
            this.user.hostname + "/message/link/download/generate", AttachmentDTO
          );
          let downloadUrl = this.user.hostname + "/message/link/download/" + result.data.download_uuid;
          this.openUrl(downloadUrl);
        // eslint-disable-next-line no-empty
        } catch {}
      }else{
        let AttachmentDTO = {
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            message_uuid: this.message_uuid,
            attachment_index: this.index,
            attachment_uuid: ''
          };
        if(this.info.attachment_uuid != undefined){
          AttachmentDTO.attachment_uuid = this.info.attachment_uuid;
          AttachmentDTO.attachment_index = 0;
        }
        try {
          let result = await this.$http.post(
            this.user.hostname + "/download/generate",
            AttachmentDTO
          );
          let downloadUrl = this.user.hostname + "/download/" + result.data.download_uuid;
          this.openUrl(downloadUrl);
        // eslint-disable-next-line no-empty
        } catch {}
      }
    },
    openUrl(downloadUrl)
    {
      try {
        //console.log("OPENING URL");
        let element = document.createElement("a");
        element.setAttribute('target', '_blank');
        element.style = "display: none";
        element.href = downloadUrl;
        element.download = this.info.name;
        document.body.appendChild(element);
        //console.log("CLICK");
        element.click();
        document.body.removeChild(element);
      } catch (e) {
        //console.log(e);
      }
    },
    SaveToFilestorage() {
      this.show = true;
      this.folder_uuid = '';
    }
  }
};
</script>
<style>
</style>